<template>
<div>
    <section id="knowledge-base-content">
        <b-overlay :show="loading" no-wrap> </b-overlay>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-body>
                        <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                            <b-button @click="prevPlan()" :disabled="selectedIndex == 0">◄ Önceki Hafta</b-button>
                            <h1>{{ getTitle }}</h1>
                            <b-button @click="nextPlan()" :disabled="selectedIndex == myCalendar.length - 1">Sonraki Hafta ►</b-button>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-button @click="isMultipleDownload=!isMultipleDownload">Çoklu İndirme Aktif / Pasif </b-button>
            </b-col>
            <b-col>
                <b-button @click="downloadMutliple">Seçili Olanları İndir</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body class="card-company-table">
                    <b-card-body>
                        <app-collapse type="margin">
                            <b-form-checkbox-group v-model="multipleDownloadIds" id="multipleDownload" name="multipleDownload">
                            <div v-for="selec in selectedPlan.entries" :key="selec.id" style="display:flex;align-items:center;gap:5px">
                            <b-form-checkbox :value="selec.id" v-if="isMultipleDownload">Seç</b-form-checkbox>
                            <app-collapse-item style="flex:1">
                                <template slot="header">
                                    <div style="flex: 1;display: flex; gap:5px; flex-direction:column; justify-content: space-between;">
                                    <div style="flex: 1;display: flex;justify-content: space-between;">
                                        <span>{{ getSectionTitle(selec) }}</span>
                                        <b-button @click="getReport(selec)">
                                            Raporla
                                        </b-button>
                                    </div>
                                    <b-progress class="mt-2" :max="100" show-value>
                                        <b-progress-bar :value="getProgressInfo(selec,'sending')" variant="success"></b-progress-bar>
                                        <b-progress-bar :value="getProgressInfo(selec,'waiting')" variant="danger"></b-progress-bar>
                                    </b-progress>
                                    </div>
                                </template>
                                <b-table :items="selec.details" responsive :fields="childFields" class="mb-0">
                                    <template #cell(isSended)="data">
                                        <b-button block :variant="data.item.isSended ? 'flat-success':'flat-default'" class="font-weight-bolder mb-12">{{ data.item.isSended ? 'Gönderildi':'Gönderilmedi'}}</b-button>
                                    </template>
                                    <template #cell(action)="data">
                                        <b-button block :variant="data.item.isSended ? 'flat-success':'flat-default'" class="font-weight-bolder mb-12" @click="sendWeekPlan({ type:'single',detailId:data.item.id,item:data.item})">{{data.item.isSended ? 'Raporlar':'Ödev Gönderilmedi'}}</b-button>
                                    </template>
                                </b-table>
                            </app-collapse-item>
                            </div>
                            </b-form-checkbox-group>
                        </app-collapse>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
    <b-modal v-model="singleReport" scrollable size="xl" hide-footer>
        <TestReportModal v-if="singleReport && selectSingleType =='Test'" :lessonId="selectedSingleReport.lessonId" :childLessonId="selectedSingleReport.childLessonId" :topicId="selectedSingleReport.topicId" :videoGroupId="selectedSingleReport.videoGroupId" :classId="selectedSingleReport.classId" :testId="selectedSingleReport.elementId" />
        <VideoReportModal v-if="singleReport && selectSingleType =='Video'" :lessonId="selectedSingleReport.lessonId" :childLessonId="selectedSingleReport.childLessonId" :topicId="selectedSingleReport.topicId" :videoGroupId="selectedSingleReport.videoGroupId" :classId="selectedSingleReport.classId" :videoId="selectedSingleReport.elementId" />
        <PercentReportModal v-if="singleReport && selectSingleType =='Percent'" :lessonId="selectedSingleReport.lessonId" :childLessonId="selectedSingleReport.childLessonId" :topicId="selectedSingleReport.topicId" :videoGroupId="selectedSingleReport.videoGroupId" :classId="selectedSingleReport.classId" :testId="selectedSingleReport.elementId" />
    </b-modal>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BSidebar,
    BTooltip,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BCardText,
    BListGroup,
    BListGroupItem,
    BProgressBar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import SelectedStudent from "./PageComponent/SelectedStudent.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import "moment/locale/tr";
import TestReportModal from "./modals/TestReportModal.vue"
import VideoReportModal from "./modals/VideoReportModal.vue"
import PercentReportModal from "./modals/PercentReportModal.vue";
import XLSX from 'xlsx';
export default {
    components: {
        flatPickr,
        SelectedStudent,
        vSelect,
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BFormCheckboxGroup,
        BBadge,
        BSidebar,
        BTooltip,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress,
        BOverlay,
        BCardText,
        BListGroup,
        BListGroupItem,
        TestReportModal,
        VideoReportModal,
        PercentReportModal,
        BProgressBar
    },
    data() {
        return {
            fields: [
                { key: "date", label: "Hafta" },
                { key: "lessonName", label: "Ders" },
                { key: "childLessonName", label: "Alt Ders" },
                { key: "topicName", label: "Konu" },
                { key: "videoGroupName", label: "Video Grup" },
                { key: "action", label: "İşlemler" },
            ],
            childFields: [
                { key: "className", label: "Sınıf" },
                { key: "elementType", label: "Ödev Tipi" },
                { key: "elementName", label: "Ödev" },
                { key: "isSended", label: "Durumu" },
                { key: "sendAt", label: "Gönderme Tarihi" },
                { key: "endAt", label: "Bitiş Tarihi" },
                { key: "action", label: "İşlemler" },
            ],
            myPlans: [],
            myCalendar: [],
            classList: [],
            selectedPlan: null,
            loading: false,
            selectedIndex: 0,
            singleReport: false,
            selectedSingleReport: null,
            selectSingleType:null,
            isMultipleDownload:false,
            multipleDownloadIds:[]
        };
    },
    computed: {
        getTitle() {
            if (this.selectedPlan == null)
                return "Öncelikle Plan Oluşturmanız Gerekli.";
            return (
                this.formattedDate(this.selectedPlan.startAt) +
                " - " +
                this.formattedDate(this.selectedPlan.endAt)
            );
        }
    },
    created() {
        this.getMyPlans();
    },
    methods: {
        async getMyPlans() {
            this.loading = true;
            var plans = await this.$http.get("Report/WeeklyPlan");
            this.myPlans = plans.data.data;

            const uniqueWeeks = Array.from(
                new Set(this.myPlans.map(item => `${item.year}-${item.week}`))
            ).map(key => {
                const [year, week] = key.split('-').map(Number);
                const groupItems = this.myPlans.filter(item => item.year === year && item.week === week);

                const startAt = groupItems.reduce((minDate, item) => new Date(item.startAt) < new Date(minDate) ? item.startAt : minDate, groupItems[0].startAt);

                const endAt = groupItems.reduce((maxDate, item) => new Date(item.endAt) > new Date(maxDate) ? item.endAt : maxDate, groupItems[0].endAt);

                return {
                    year: year,
                    week: week,
                    startAt: startAt,
                    endAt: endAt,
                    entries: groupItems
                };
            });

            this.myCalendar = uniqueWeeks;

            const date = new Date();
            const startOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date - startOfYear) / 86400000;

            const currentWeek = Math.ceil(
                (pastDaysOfYear + startOfYear.getDay() + 1) / 7
            );

            this.selectedPlan = this.myCalendar.find((x) => x.week == currentWeek);
            this.selectedIndex = this.myCalendar.findIndex((x) => x.week == currentWeek);
            if (this.selectedPlan == null) {
                this.selectedIndex = 0;
                this.selectedPlan = this.myCalendar[0];
            }

            this.loading = false;
        },
        getSectionTitle(item) {
            return `${item.teacherName} - ${item.lessonName} - ${item.childLessonName} - ${item.topicName} - ${item.videoGroupName}`;
        },
        getProgressInfo(section,type){
            const check = type == "sending";
            const entries = section.details.filter(x=>x.isSended == check);

            const percent = (100 / section.details.length) * entries.length;
            return percent;
        },
        prevPlan() {
            const nextIndex = this.selectedIndex - 1;
            if (nextIndex >= 0) {
                this.selectedPlan = this.myCalendar[nextIndex];
                this.selectedIndex = nextIndex;
            }
        },
        nextPlan() {
            const nextIndex = this.selectedIndex + 1;
            if (nextIndex < this.myCalendar.length) {
                this.selectedPlan = this.myCalendar[nextIndex];
                this.selectedIndex = nextIndex;
            }
        },
        formattedDate(dateString) {
            return moment(dateString, "DD.MM.YYYY").format("DD MMMM YYYY");
        },
        async sendWeekPlan(req) {
            this.loading = true;
            const request = {
                week: this.selectedPlan.week,
                year: this.selectedPlan.year,
            };

            if (req.type == "class") {
                request.classId = req.classId;
            }

            if (req.type == "single") {
                request.detailId = req.detailId;
            }

            if (req.item?.isSended == true) {
                //open report
                const parent = this.selectedPlan.entries.find(entry => 
                    entry.details.some(detail => detail.id === req.item.id)
                );

                this.selectedSingleReport = {
                    classId:req.item.classId,
                    elementId:req.item.elementId,
                    lessonId:parent.lessonId,
                    childLessonId:parent.childLessonId,
                    topicId:parent.topicId,
                    videoGroupId:parent.videoGroupId
                };

                this.selectSingleType = req.item.elementType;

                this.singleReport = true;
                this.loading = false;
                return;
            }

            this.loading = false;
        },
        getReport(data){
            console.log(data);
            let classIds = data.details.map(dt=>{
                if(dt.isSended == true)
                    return dt.classId
                else return null;
            });
            let testIds = data.details.map(dt=>{
                if(dt.elementType == "Test" && dt.isSended == true) return dt.elementId;
                else return null
            });

            testIds = testIds.filter(x=>x != null);
            classIds = classIds.filter(x=>x != null);
            classIds = [...new Set(classIds)];
            testIds = [...new Set(testIds)];

            if(testIds.length == 0){
                return;
            }

            this.selectedSingleReport = {
                classId:classIds,
                elementId:testIds,
                lessonId:data.lessonId,
                childLessonId:data.childLessonId,
                topicId:data.topicId,
                videoGroupId:data.videoGroupId
            };

            this.selectSingleType = "Percent";

            this.singleReport = true;
        },
        async downloadMutliple(){
            const datas = this.selectedPlan.entries;
            const exportDatas=[];
            for(const data of datas){
                if(this.multipleDownloadIds.some(x=>x == data.id)){
                    let classIds = data.details.map(dt=>{
                        if(dt.isSended == true)
                            return dt.classId
                        else return null;
                    });

                    let testIds = data.details.map(dt=>{
                        if(dt.elementType == "Test" && dt.isSended == true) return dt.elementId;
                        else return null
                    });

                    testIds = testIds.filter(x=>x != null);
                    classIds = classIds.filter(x=>x != null);
                    classIds = [...new Set(classIds)];
                    testIds = [...new Set(testIds)];

                    if(testIds.length == 0){
                        return;
                    }

                    var request = {
                        lessonId: data.lessonId,
                        class: classIds,
                        childLessonId: data.childLessonId,
                        topicId: data.topicId,
                        videoGroupId: data.videoGroupId,
                        testId: testIds,
                    };

                var userData = await this.$http.post("Report/TestRatesUser", request);
                exportDatas.push(...userData.data.data.data);
                }
            }
            if(exportDatas.length>0){
                await this.downloadExcel(exportDatas);
            }
        },
        async downloadExcel(exportData) {
            var ws_data = new Array();
            var lessonsrow = new Array();
            let uniqueKeys = new Set();

            exportData.forEach(obj => {
                Object.keys(obj).forEach(key => uniqueKeys.add(key));
            });

            let uniqueStudents = new Set();
            exportData.forEach(obj => {
                uniqueStudents.add(obj.idNumber);
            });

            const headers = [];
            for(const row of uniqueKeys){
                if(row==='avarage') continue;
                let header = row;
                if(row == "class") header= "Sınıf";
                if(row == "studentName") header = "İsim Soyisim";
                headers.push(header);
            }

            ws_data.push(headers);

            for(const studentId of uniqueStudents){
                const row =[];
                const studentDatas = exportData.filter(x=>x.idNumber == studentId);
                if(studentDatas.length>0){
                    for(const col of uniqueKeys){
                        if(col==='avarage') continue;
                        const result = studentDatas.find(obj => obj.hasOwnProperty(col));
                        if(result){
                            row.push(result[col] || "0")
                        }else{
                            row.push("0");
                        }
                    }
                }
                ws_data.push(row);
            }


            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Percent Report",
                Subject: "Percent Report",
                Author: "Yes Akademi",
                CreatedDate: new Date()
            };

            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            wb.SheetNames.push("Sayfa1");
            ws["!cols"] = [{ width: 20 }, { width: 30 }]; //student names width
            for (let index = 0; index < 30; index++) {
                ws["!cols"].push({ width: 25 });
            }

            wb.Sheets["Sayfa1"] = ws;
            await XLSX.writeFile(wb, "percentReport.xlsx");

        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/pages/page-knowledge-base.scss";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}

.scroll {
    max-height: 500px;
    overflow-y: auto;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.item span {
    font-weight: bold;
}
</style>
